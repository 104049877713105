body{
    overflow: hidden;
}
.card1{
    width: 100%;
    height: 500px;
    padding-left: 200px;
    padding-top: 100px;
    transform: skewY(-5deg);
    overflow: hidden;
    
    display: flex;
    @media screen and (max-width:  1170px ) {
        padding-left: 50px;
    }


    &__container{
        transform: skewY(5deg);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        &__item{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: start;

            &__tittle{

                & h1{
                    font-size: 40px;
                    color: white;
                    font-weight: 1000;
                    @media screen and (max-width:  1170px ) {
                        font-size: 30px;
                    }
                }
            }

            &__text{

                & p{
                    font-size: 24px;
                    color: white;
                    font-weight: 400;
                    @media screen and (max-width:  1170px ) {
                        font-size: 18px;
                    }
                }
            }

            &__footer{

                & h1{
                    font-size: 40px;
                    color: white;
                    font-weight: 1000;
                    @media screen and (max-width:  1170px ) {
                        font-size: 30px;
                    }
                }
                &__buttons{
                    display: flex;
                    gap: 10px;
                }
            }
        }
    }
}

.card2 {
    width: 100%;
    height: 500px;
    background: black;
    transform: skewY(-5deg);
    overflow: hidden;


    &__container{
        transform: skewY(5deg);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 80px;

        & h1{
            font-size: 48px;
            color: white;
            font-weight: 1000;
            @media screen and (max-width:  1170px ) {
                font-size: 30px;
            }
        }

        &__line{
            width: 340px;
            height: 5px;
            background: linear-gradient(90deg, #3463E3 0%, #1D377D 100%);
            @media screen and (max-width:  1170px ) {
                padding-left: 50px;
            }
        }
    }
}
.card3 {
    width: 100%;
    height: 500px;
    background: #11141A;
    transform: skewY(4deg);
    margin-top: -250px;
    
    
    overflow: hidden;
    &__container{
        transform: skewY(-4deg);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 60px;
        overflow: hidden;

        & h1{
            font-size: 48px;
            color: white;
            font-weight: 1000;
            letter-spacing: 2px;

            @media screen and (max-width:  1170px ) {
                font-size: 30px;
            }
        }

        &__decoration{
            margin-left: -220px;
            width: 270px;
            height: 75px;
            position: absolute;
            z-index: -1;
            background: linear-gradient(90deg, #3463E3 0%, #1D377D 100%);
            border-radius: 10px;

            @media screen and (max-width:  1170px ) {
                margin-left: -145px;
                width: 170px;
                height: 50px;
            }
        }
        & p{
            font-size: 32px;
            width: 500px;
            letter-spacing: 0px;
            line-height: 30px;

            @media screen and (max-width:  1170px ) {
                font-size: 18px;
                width: 300px;
                letter-spacing: 0px;
                line-height: 30px;
            }
        }
    }
}
.card4 {
    width: 100%;
    height: 500px;
    background: #2F2F2F;
    transform: skewY(2deg);
    margin-top: -60px;
    overflow: hidden;
    @media screen and (max-width:  1170px ) {
        height: 550px;
    }

    &__container{
        transform: skewY(-2deg);
        width: 100%;
        height: 100%;
        z-index: -1;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 60px;
        overflow: hidden;
        

        &__tittle{
            display: flex;
            gap: 20px;
            & h1{
                font-size: 48px;
                color: white;
                font-weight: 1000;

                &:nth-child(2){
                    color: #FFA800;
                        overflow: hidden;
                }

                @media screen and (max-width:  1170px ) {
                    font-size: 30px;
                }
            }
            @media screen and (max-width:  1170px ) {
                gap: 0px;
                flex-direction: column;
            }
        }

        & p{
            font-size: 36px;
            width: 900px;
            letter-spacing: 0px;
            line-height: 35px;

            @media screen and (max-width:  1170px ) {
                font-size: 18px;
                width: 300px;
                letter-spacing: 0px;
                line-height: 30px;
            }
        }
    }
}
.card5 {
    width: 100%;
    height: 600px;
    background: #1E1E27;
    transform: skewY(-4deg);
    margin-top: -150px;

    &__container{
        transform: skewY(4deg);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 60px;
        overflow: hidden;

        & h1{
            font-size: 48px;
            color: white;
            font-weight: 1000;
            letter-spacing: 2px;
            text-align: center;

            @media screen and (max-width:  1170px ) {
                font-size: 30px;
            }
        }

        &__decoration{
            margin-left: -655px;
            width: 325px;
            height: 75px;
            position: absolute;
            z-index: -1;
            background: linear-gradient(90deg, #3463E3 0%, #1D377D 100%);
            border-radius: 10px;

            @media screen and (max-width:  1170px ) {
                margin-left: 0px;
                width: 220px;
                height: 50px;
            }
        }
        & p{
            font-size: 36px;
            width: 975px;
            letter-spacing: 0px;
            line-height: 35px;

            @media screen and (max-width:  1170px ) {
                font-size: 18px;
                width: 300px;
                letter-spacing: 0px;
                line-height: 30px;
            }
        }
    }
}