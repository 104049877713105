.navbar{
    width: 100%;
    height: 100px;
    display: flex;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
    overflow: hidden;

    @media screen and (max-width:  580px ) {
        height: 120px;
    }


    &__container{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: start;
        @media screen and (max-width:  580px ) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 50px;
        }

        &__tittle{
            display: flex;
            justify-content: center;
            align-items: center;

            &__line{
                width: 133px;
                height: 1px;
                background: white;
                position: absolute;
                margin-top: 35px;
                margin-left: 150px;
                @media screen and (max-width:  1170px ) {
                    width: 70px;
                    margin-top: 20px;
                    margin-left: 75px;
                }
            }
            & h1{
                font-size: 40px;
                color: white;
                font-weight: 1000;
                @media screen and (max-width:  1170px ) {
                    font-size: 20px;
                }
            }
            & h2{
                font-size: 40px;
                color: white;
                font-weight: 100;
                @media screen and (max-width:  1170px ) {
                    font-size: 20px;
                }

            }
        }

        &__list{
            height: 100%;
            display: flex;
            align-items: center;
            gap: 78px;
            @media screen and (max-width:  1170px ) {
                gap: 20px;
            }
            @media screen and (max-width:  580px ) {
                gap: 10px;
            }
            &__item{

                & p{
                    font-size: 20px;
                    color: white;
                    font-weight: 600;
                    @media screen and (max-width:  1170px ) {
                        font-size: 10px;
                    }
                    @media screen and (max-width:  580px ) {
                        font-size: 8px;
                    }
                    &:hover{
                        color: #FE9D21;
                        cursor: pointer;
                    }
                }
            }
        }
    }

}

