.button{
    border: none;
    padding: 10px 20px;
    font-size: 1.125rem;
    border-radius: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;

    &__login{
        background: #343434;
        color: white;
        font-weight: 600;
        font-size: 20px;
        width: 200px;
        height: 45px;
        transition: .25s;
        border-radius: 10px;
        @media screen and (max-width:  1170px ) {
            font-size: 10px;
            width: 100px;
            height: 30px;
        }


        &:hover{
            background: #242424;

        }
    }
    &__reg{
        background: #34343442;
        box-shadow: inset 2px 2px 2px #343434, inset -2px -2px 2px #343434;
        color: white;
        font-weight: 600;
        font-size: 20px;
        width: 200px;
        height: 45px;
        transition: .25s;
        border-radius: 10px;
        @media screen and (max-width:  1170px ) {
            font-size: 10px;
            width: 100px;
            height: 30px;
        }


        &:hover{
            background: #242424;

        }
    }
}