@use './abstracts/' as *;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;-webkit-user-select: none;-ms-user-select: none;
    user-select: none;-webkit-user-drag: none;-webkit-user-zoom: none;-webkit-touch-callout: none;
    user-zoom: none;
}

html {
    overflow-x: hidden;
}

body {
    font-family: $fontFamily;
    color: $txt_color;
    font-size: $fontSizeNormal;
    line-height: 1.5;
    background: #0F0F0F;
    background-attachment: fixed;
    overflow-x: hidden;
    height: 100%;
}

a {
    text-decoration: none;
    color: unset;
}

p {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin: 20px 0;
}

span {
    font-size: 12px;
}

ul,
li {
    list-style-type: none;
}

.mb {
    margin-bottom: $spacing;

    @include tablet {
        margin-bottom: $mobile_spacing;
    }
}

.mb-big {
    margin-bottom: $spacing + 25px;

    @include tablet {
        margin-bottom: $mobile_spacing;
    }
}

.text-center-horizontal {
    text-align: center;
}

.text-center-vertical {
    align-self: center;
    margin-left: 20px;
}

.title {
    font-size: $fontSizeMedium;
    font-weight: 700;
}

::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: #5b5b5b;
    border-radius: 20px;
}
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
@font-face {
    font-family: "Impact";
    src: local("Impact"),
    url("../fonts/impact/impact.ttf") format("truetype");
    font-weight: bold;
}