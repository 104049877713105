@use './abstracts/' as *;
@use 'sass:math';

.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 math.div($spacing, 2) * -1;
    @media screen and (max-width:  690px ) {
        justify-content: center;
        display: flex;
        flex-direction: column;
    }
    &-gap {
        &-5{
            gap: 5px;
        }
        &-10{
            gap: 10px;
        }
        &-15{
            gap: 15px;
        }
        &-20{
            gap: 20px;
        }
        &-25{
            gap: 25px;
        }
        
    }
    
    @include mobile {
        margin: 0 math.div($mobile_spacing, 2) * -1;
    }
}
.fdc{
    display: flex;
    flex-direction: column;
}
.row-comp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 math.div($spacing, 2) * -1;

    &-gap {
        &-5{
            gap: 5px;
        }
        &-10{
            gap: 10px;
        }
        &-15{
            gap: 15px;
        }
        &-20{
            gap: 20px;
        }
        &-25{
            gap: 25px;
        }
        
    }
    
    @include tablet {
        margin: 0 math.div($mobile_spacing, 2) * -1;
    }
}

.align-items-center {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.align-items-center-vertical {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.align-items-center-horizontal {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
[class*="col-"] {
    padding: 0 math.div($spacing, 2);

    @include tablet {
        padding: 0 math.div($mobile_spacing, 2);
    }
}

.col-box {
    @include mobile {
        padding: 0;
    }
}

@for $i from 1 through 12 {
    $v: math.div($i, 12) * 100;
    .col-#{$i} {
        width: $v*1%;
    }
}

@include tablet {
    @for $i from 1 through 12 {
        $v: math.div($i, 12) * 100;
        .col-md-#{$i} {
            width: $v*1%;
        }
    }

    .hide-md {
        display: none;
    }
}

@include mobile {
    @for $i from 1 through 12 {
        $v: math.div($i, 12) * 100;
        .col-sm-#{$i} {
            width: $v*1%;
        }
    }

    .hide-sm {
        display: none;
    }
}