$txt_color: #fff;

$fontFamily: 'Montserrat', sans-serif;

$fontSizeNormal: 1.125rem;
$fontSizeMedium: 1.25rem;
$fontSizeLarge: 1.5625rem;

$white: #fff;
$black: #FE9D21;

$spacing: 20px;
$mobile_spacing: 24px;
$border_radius: 30px;

$mobile_width: 600px;
$tablet_width: 1366px;